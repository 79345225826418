module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/gatsby-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"a0465342fc226feae202465b72bd7a8d"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"ahl-nz","accessToken":"MC5YeGRzb0JFQUFDUUFpWDZ4.Vxbvv73vv71a77-9UwTvv71Jb--_vQtoXu-_vW7vv73vv73vv70c77-977-9P--_vSnvv70eVO-_vTHvv70","schemas":{"page":{"Main":{"page_id":{"type":"Text","config":{"label":"Page ID"}},"page_title":{"type":"StructuredText","config":{"single":"heading1, heading2, heading3, heading4, heading5, heading6","label":"Page Title"}},"page_text_1":{"type":"StructuredText","config":{"multi":"paragraph, preformatted, heading1, heading2, heading3, heading4, heading5, heading6, strong, em, hyperlink, image, embed, list-item, o-list-item, o-list-item","label":"Page Text 1"}},"page_text_2":{"type":"StructuredText","config":{"multi":"paragraph, preformatted, heading1, heading2, heading3, heading4, heading5, heading6, strong, em, hyperlink, image, embed, list-item, o-list-item, o-list-item","label":"Page Text 2"}},"page_image":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Page Image"}}}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
